import React from 'react';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Services = ({ data }) => {
	const services = data.allPrismicServices.edges;
	return (
		<Layout bodyClass="page-services">
			<SEO title={data.prismicAllServices.data.title.text} />
			<Helmet>
				<meta
					name="description"
					content={data.prismicAllServices.data.intro.text}
				/>
				<link
					rel="canonical"
					href={`${data.site.siteMetadata.siteUrl}en/services/`}
				/>
				<link
					rel="alternate"
					href={`${data.site.siteMetadata.siteUrl}en/services/`}
					hreflang="en"
				/>
				<link
					rel="alternate"
					href={`${data.site.siteMetadata.siteUrl}en/services/`}
					hreflang="sv-se"
				/>
			</Helmet>
			<div className="intro">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<h1>{data.prismicAllServices.data.title.text}</h1>
							<p>{data.prismicAllServices.data.intro.text}</p>
						</div>
					</div>
				</div>
			</div>

			<div className="container pb-6">
				<div className="row">
					{services.map(edge => (
						<div
							key={edge.node.data.service_name.text}
							className="col-12 col-md-4 mb-1"
						>
							<div className="card service service-teaser">
								<div className="card-content">
									<Link to={`en/services/${edge.node.uid}`}>
										<h2>{edge.node.data.service_name.text}</h2>
									</Link>
									<p>{edge.node.data.service_intro.text}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="container pt-5 pb-5 pt-md-7 pb-md-7">
				{/* just to add some spacing between services and the footer */}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
		prismicAllServices(lang: { eq: "en-gb" }) {
			data {
				title {
					text
				}
				intro {
					text
				}
			}
		}
		allPrismicServices(filter: { lang: { eq: "en-gb" } }) {
			edges {
				node {
					id
					uid
					data {
						service_name {
							text
						}
						service_intro {
							text
						}
					}
				}
			}
		}
	}
`;

export default Services;
